import React from 'react'
import { useEffect } from 'react'
import ReactGA from 'react-ga'

import { useRouter } from 'helpers/route.helper'

interface AnalyticsProps {
  devEnv: boolean
}

export const AnalyticsComponent = ({ devEnv }: AnalyticsProps) => {
  const [, location] = useRouter()
  // Google Analytics.
  useEffect(() => {
    ReactGA.initialize('UA-167630986-1', {
      testMode: devEnv, // Don't send data in development mode..
      // debug: devEnv, // NOTE: Uncomment to enable logging to console in development mode.
    })
    ReactGA.set({ dimension1: window.env.ENVIRONMENT })
    ReactGA.set({ dimension2: window.env.VERSION })
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  useEffect(() => {
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname)
  }, [location])

  return <></>
}
